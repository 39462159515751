/* app/ui/calendar/calendar-filter */

define(
	[
		'jquery',
		'app/ui/filter/date-picker-accessible',
		'pubsub'
	],
	function ($, DatePicker) {

		'use strict';

		var CalendarFilter;
		var $calendar;
		var $content;
		var $typeFilter;
		var $typeCheckboxes;
		var $committeeToggle;
		var $committeeFilter;
		var $committeeCheckboxes;
		var $filterToggle;
		var $filterForm;
		var $calExport;

		var $calendarItems;
		var $committeeItems;
		var committeeOptionsCount;

		var selectors = {
			checkbox: 'input:checkbox',
			typeFilter: '.js-calendar-options',
			committeeToggle: '.js-committee-checkbox',
			committeeFilter: '.js-committee-options',
			filterToggle: '.js-filter-toggle',
			filterForm: '.js-filter-form',
			calExport: '.js-calendar-export'
		};

		var classes = {
			hidden: 'hidden',
			open: 'is-open'
		};

		var filterHooks = {
			value: 'data-filter-val',
			type: 'data-calendar-type',
			committee: 'data-committee-type'
		};

		return {

			init: function($cal, $cont) {
				CalendarFilter = this;

				$calendar = $cal;
				$content = $cont;
				$typeFilter = $calendar.find(selectors.typeFilter);
				$typeCheckboxes = $typeFilter.find(selectors.checkbox);
				$committeeToggle = $typeFilter.find(selectors.committeeToggle);
				$committeeFilter = $calendar.find(selectors.committeeFilter);
				$committeeCheckboxes = $committeeFilter.find(selectors.checkbox);
				$filterToggle = $calendar.find(selectors.filterToggle);
				$filterForm = $calendar.find(selectors.filterForm);
				$calExport = $calendar.find(selectors.calExport);

				DatePicker.init();
				CalendarFilter._updateCommitteeOptionsCount();
				CalendarFilter._initEvents();
				CalendarFilter._initElements();
				CalendarFilter._initSubscriptions();
			},

			// Create jQuery collections of filterable elements
			// 1. All items in the calendar. Filterable by the main (coloured) filter options
			// 2. 'Committee Business' items in the calendar have an addition data attribute as a hook for the subfilter
			_initElements: function () {
				$calendarItems = $content.find('[' + filterHooks.type + ']');
				$committeeItems = $content.find('[' + filterHooks.committee + ']');
				$typeCheckboxes.trigger('change');
			},

			// Listeners for filter checkbox changes
			_initEvents: function () {
				$typeCheckboxes.off('change', CalendarFilter._processTypeChange);
				$committeeCheckboxes.off('change', CalendarFilter._processCommitteeChange);
				$filterToggle.off('click', CalendarFilter._toggleFilterForm);
				$calExport.off('click', CalendarFilter.exportCalendar);

				$typeCheckboxes.on('change', CalendarFilter._processTypeChange);
				$committeeCheckboxes.on('change', CalendarFilter._processCommitteeChange);
				$filterToggle.on('click', CalendarFilter._toggleFilterForm);
				$calExport.on('click', CalendarFilter.exportCalendar);
			},

			// 1. Re-initialise filterable elements when new content is loaded
			_initSubscriptions: function () {
				$.subscribe('/calendar/loaded', CalendarFilter._initElements);
			},

			// Runs on change of the main (coloured) filter options
			// 1. Checkbox ID used as filter value
			// 2. Determine whether to hide or show the matched elements
			// 3. If the 'Committee Business' checkbox is changes, toggle the display of the sub filters
			// 4. Filter all calendar items to those matching the filter value and hide/show
			// 5. Pass the committee items on to be filtered by the committee business sub filters
			_processTypeChange: function () {
				var $checkbox = $(this);
				var eventType = $checkbox.attr(filterHooks.value);
				var action = $checkbox.is(':checked') ? 'removeClass' : 'addClass';

				if ($checkbox.is(selectors.committeeToggle)) {
					CalendarFilter._toggleCommitteeOptions();
				}

				$calendarItems.filter('[' + filterHooks.type + '~="' + eventType + '"]')[action](classes.hidden);
				CalendarFilter._filterCommitteeItems($committeeItems);
			},

			// Runs on change of the 'Committee Business' sub filter options
			// 1. Update 'committeeOptionsCount' variable
			// 2. If option is checked ensure the main 'Committee Business' filter is also checked
			// 3. If no sub filter options are selected un-check the 'Committee Business' filter
			// 4. Pass the committee items on to be filtered by the committee business sub filters
			_processCommitteeChange: function () {
				var $checkbox = $(this);

				CalendarFilter._updateCommitteeOptionsCount();

				if ($checkbox.is(':checked')) {
					$committeeToggle.prop('checked', true).trigger('change');
				}

				if (committeeOptionsCount === 0) {
					$committeeToggle.prop('checked', false).trigger('change');
				}

				CalendarFilter._filterCommitteeItems($committeeItems);
			},

			// Runs on change of the 'Committee Business' main filter option
			// Toggles sub filter options between checked and un-checked states
			// 1. If 'Committee Business' filter is checked and no sub filter options are - select them all
			// 2. If 'Committee Business' filter is un-checked, un-check all sub filter options
			// 3. Update 'committeeOptionsCount' variable
			_toggleCommitteeOptions: function () {
				var isSelected = $committeeToggle.is(':checked');

				if (isSelected && committeeOptionsCount === 0) {
					$committeeCheckboxes.prop('checked', true);
				}

				if (!isSelected) {
					$committeeCheckboxes.prop('checked', false);
				}

				CalendarFilter._updateCommitteeOptionsCount();
			},

			// Run committee sub filters over committee items
			// 1. Determine how many items are in collection
			// 2. Determine whether any sub filter options are unchecked
			// 3. Run filtering if 'Committee Business' filter is checked and at least 1 sub filter option is un-checked
			// 4. Run each item through a loop of selected sub filter options
			// 5. If a match is found, increment 'hits' variable. Only 1 hit is needed to prevent item being hidden
			// 6. If no matched, apply 'hidden' class to item
			_filterCommitteeItems: function ($elms) {
				var committeeItemsCount = $elms.length;
				var hasHiddenOptions = committeeOptionsCount < $committeeCheckboxes.length;

				if ($committeeToggle.is(':checked') && committeeItemsCount && hasHiddenOptions) {
					var selectedOptions = CalendarFilter._getSelectedCommitteeOptions();

					for (var i = 0; i < committeeItemsCount; i++) {
						var $item = $($elms[i]);
						var hits = 0;

						for (var j = 0; j < committeeOptionsCount; j++) {
							var optionValue = selectedOptions[j].getAttribute(filterHooks.value);

							if (hits === 0 && $item.is('[' + filterHooks.committee + '~="' + optionValue + '"]')) {
								$item.removeClass(classes.hidden);
								hits++;
							}
						}

						if (!hits) {
							$item.addClass(classes.hidden);
						}
					}
				}
			},

			// Util function to update 'committeeOptionsCound' variable
			_updateCommitteeOptionsCount: function () {
				committeeOptionsCount = CalendarFilter._getSelectedCommitteeOptions().length;
			},
			
			// Util function to return collection of checked sub filter options
			_getSelectedCommitteeOptions: function() {
				return $committeeCheckboxes.filter(':checked');
			},

			_toggleFilterForm: function() {
				$filterForm.toggleClass(classes.open);
			},

			exportCalendar: function (e) {
				var url = $calExport.attr('href');
				url = url.split('?')[0];
				url = url + '?' + $calExport.closest('form').serialize();
				$calExport.attr('href', url);
			},

			// Run filters over new content AJAX'ed into the calendar
			// 1. Create jQuery object of new data
			// 2. Create jQuery collection of un-checked filters
			// 3. Only run main filtering if there are un-checked filters
			// 4. Adds 'hidden' class to any calendar items matching the un-checked filter values
			// 5. If 'Committee Business' filter is checked, pass new committee items on to be filtered by the committee business sub filters
			// 6. Returns jQuery object with filtering applied
			processNewContent: function (html) {
				var $html = $(html);
				var $hiddenTypes = $typeCheckboxes.not(':checked');
				var hiddenCount = $hiddenTypes.length;

				if (hiddenCount) {
					var $items = $html.find('[' + filterHooks.type + ']');

					for (var i = 0; i < $items.length; i++) {
						var $item = $($items[i]);
						var isHidden = false;

						for (var j = 0; j < hiddenCount; j++) {
							var hiddenType = $hiddenTypes[j].getAttribute(filterHooks.value);

							if (!isHidden && $item.is('[' + filterHooks.type + '~="' + hiddenType + '"]')) {
								$item.addClass(classes.hidden);
								isHidden = true;
							}
						}
					}
				}

				if ($committeeToggle.is(':checked')) {
					var $commItems = $html.find('[' + filterHooks.committee + ']');
					CalendarFilter._filterCommitteeItems($commItems);
				}

				return $html;
			}
		};
	}
);