/* app/ui/video/video.player */

define(
	[
		'jquery'
	],
	function ($) {

		var VideoPlayer;
		var $player;

		return {
			init: function ($container) {
				
				VideoPlayer = this;
				$player = $container ? $container.find('.js-video-player') : $('.js-video-player');
				VideoPlayer._initEvents();
			},

			_initEvents: function () {
				$player.on('click', '.js-video-cover', VideoPlayer._loadVideo);
			},

			// Retrieve embed URL from link
			// Create iframe embed element
			// Insert iframe into player element
			// Add class to cover to control its appearance
			_loadVideo: function (event) {
				event.preventDefault();
				var $target = $(event.target);
				var $cover = $target.closest('.js-video-cover');
				$player = $(event.target).closest('.js-video-player');
				var embedSrc = $cover.attr('href');

				var $embedElm = $('<iframe src="' + embedSrc + '?autoplay=1&rel=0" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');

				$player.prepend($embedElm);
				$cover.addClass('is-playing');

				$.publish('video/play');
			}
		};
	}
);