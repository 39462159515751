/* app/ui/filter/date-picker-accessible */

define(
    [
        'jquery',
        'util/core',
        'moment',
        '@duetds/date-picker/dist/loader'
    ],
    function ($, Core, Moment, duet) {

        'use strict';
        
        // Register Duet Date Picker
        duet.defineCustomElements(window);

        var DatePicker;
        var classes = {
            error: 'in-error'
        };
        var selectors = {
            form: '.js-filter-form',
            formSubmit: '.js-filter-submit',
            dateWrapper: '.js-date-range',
            rangeError: '.js-range-error',
            timeframeSelector: '.js-timeframe-select',
            customTimeframeInputs: '.js-custom-input'
        };
        var specifiedRangeValue = 'range';

        var dateFromField = null;
        var dateFromButton = null;
        var dateToField = null;
        var dateToButton = null;
        var datePickerFrom = null;
        var datePickerTo = null;
        var filterStartDate = "";
        var filterEndDate = "";
        var parliamentStartDate = "";
        var parliamentEndDate = "";
        var $form;
        var $formSubmit;
        var $dateWrapper;
        var $rangeError;
        var $timeframeSelector;
        var $customTimeframeInputs;

        return {
            init: function () {
                DatePicker = this;
                DatePicker._initElements();

                if ($dateWrapper.length) {
                    DatePicker._initPickers();
                    DatePicker._initEvents();
                }
            },

            reInit: function () {
                $timeframeSelector.off('change');
                $customTimeframeInputs.off('change');
                $formSubmit.off('click');
                $('#criteria_DateFromLabel').off('click');
                $('#criteria_DateToLabel').off('click');

                DatePicker._initElements();
                DatePicker._destroyPickers();

                if ($dateWrapper.length) {
                    DatePicker._initPickers();
                    DatePicker._initEvents();
                }
            },

            _initElements: function () {
                $form = $(selectors.form);
                $formSubmit = $form.find(selectors.formSubmit);
                $dateWrapper = $form.find(selectors.dateWrapper);
                $rangeError = $dateWrapper.find(selectors.rangeError);
                $timeframeSelector = $dateWrapper.find(selectors.timeframeSelector);
                $customTimeframeInputs = $dateWrapper.find(selectors.customTimeframeInputs);

                // Hack to make the table header show "Mon" instead of "Mo" for Monday etc
                var hackDuetDayHeaders = function (picker) {
                    $(picker).find('th.duet-date__table-header').each(function (i, x) {
                        if (x.childNodes.length != 2) return;
                        x.childNodes[0].innerText = x.childNodes[1].textContent.substring(0, 3);
                    });
                }

                $('duet-date-picker').each(function (i, picker) {
                    $(picker).one("click", function (e) { hackDuetDayHeaders(picker) });

                    var accessibleLabel = $(picker).attr('label');

                    $(picker).find('.duet-date__input').attr('aria-label', accessibleLabel);
                });
            },

            // Initialise date picker instances
            _initPickers: function () {
                dateFromField = document.getElementById('criteria_DateFromField');
                dateFromButton = document.getElementById('criteria_DateFromButton');
                dateToField = document.getElementById('criteria_DateToField');
                dateToButton = document.getElementById('criteria_DateToButton');

                if (document.getElementById("filterStartDate")) {
                    filterStartDate = document.getElementById('filterStartDate').value;
                }

                if (document.getElementById("filterEndDate")) {
                    filterEndDate = document.getElementById('filterEndDate').value;
                }

                if (document.getElementById('parliamentStartDate')) {
                    parliamentStartDate = document.getElementById('parliamentStartDate').value;
                }

                if (document.getElementById('parliamentEndDate')) {
                    parliamentEndDate = document.getElementById('parliamentEndDate').value;
                }
                
                $('#criteria_DateFromField').val(filterStartDate ? filterStartDate : parliamentStartDate ? parliamentStartDate : null);
                $('#criteria_DateToField').val(filterEndDate ? filterEndDate : parliamentEndDate ? parliamentEndDate : null);
            },

            _toString: function (date, format) {
                if (date) {
                    return new Moment(date).format(format);
                } else {
                    return '';
                }
            },

            _validateRange: function () {
                var fromDate = new Moment($('#criteria_DateFromField').val());
                var toDate = new Moment($('#criteria_DateToField').val());
                var rangeValid = true;

                if (toDate.isBefore(fromDate)) {
                    $rangeError.html('<span class="filter__error">\'To\' date must be greater than \'From\' date</span>');
                    $dateWrapper.addClass(classes.error);
                    rangeValid = false;
                } else {
                    $dateWrapper.removeClass(classes.error);
                    rangeValid = true;
                }

                return rangeValid;
            },

            _validateCustomDates: function () {
                var fromDate = new Moment($('#criteria_DateFromField').val());
                var toDate = new Moment($('#criteria_DateToField').val());
                var datesValid = true;

                if ($timeframeSelector.val() === specifiedRangeValue) {

                    if (!fromDate.isValid() || !toDate.isValid()) {
                        $rangeError.html('<span class="filter__error">Please ensure a valid date is entered in each field</span>');
                        $dateWrapper.addClass(classes.error);
                        datesValid = false;
                    } else {
                        datesValid = DatePicker._validateRange();
                    }
                }

                return datesValid;
            },

            // Destroy instances of date picker
            _destroyPickers: function () {
                if (datePickerFrom) {
                    datePickerFrom.destroy();
                }
                if (datePickerTo) {
                    datePickerTo.destroy();
                }
            },

            // Init event listeners
            _initEvents: function () {
                $timeframeSelector.on('change', DatePicker._onTimeframeChange);
                $customTimeframeInputs.on('duetChange', DatePicker._onCustomChange);
                $formSubmit.on('click', DatePicker._onFilterSubmit);

                $('#criteria_DateFromLabel').click(function() {
                    $('#criteria_DateFromField').find('.duet-date__input').focus()
                })

                $('#criteria_DateToLabel').click(function () {
                    $('#criteria_DateToField').find('.duet-date__input').focus()
                })
            },

            // Capture value of timeframe dropdown
            // Toggle disabled state of custom fields depending on dropdown value
            _onTimeframeChange: function () {
                var $selector = $(this);
                var value = $selector.val();
                var show = value === specifiedRangeValue;

                if (show) {
                    if(datePickerFrom) datePickerFrom.show();
                } else {
                    $customTimeframeInputs.val('');
                    $dateWrapper.removeClass(classes.error);
                    if(datePickerFrom) datePickerFrom.setDate(null);
                    if(datePickerTo) datePickerTo.setDate(null);
                }
            },

            _onCustomChange: function () {
                var thisDate = new Moment(this.value);

                if (thisDate.isValid()) {
                    $timeframeSelector.val(specifiedRangeValue);
                }
            },

            _onFilterSubmit: function (event) {
                event.preventDefault();

                if (DatePicker._validateCustomDates()) {
                    $form.submit();
                } else {
                    Core.scrollToElm($dateWrapper, 20);
                }
            }
        };
    }
);