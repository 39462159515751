define(
	[
		'jquery'
	],

	function ($) {

		var timeout,
			throttle = 500;

		return {
			init: function () {
				this._initEvents();

				$(window).trigger('fbresize');
			},

			_initEvents: function () {
				$(window).on('resize fbresize', this._processResize);
			},

			_processResize: function () {
				// Throttle resize of embedded Facebook pages
				if (timeout) {
					clearTimeout(timeout);
				}

				timeout = setTimeout(function () {
					// Recalculate the widths of any embedded Facebook pages
					$('.fb-page').each(function () {
						var $this = $(this);
						$this.children('[style]').css('width', $this.parent().outerWidth());

						// Ensure it stays square
						$this.attr('data-height', $this.parent().outerHeight());
					});

					// Force the embedded Facebook pages to be rerendered
					if (typeof FB !== 'undefined') {
						FB.XFBML.parse();
					}
				}, throttle);
			}
		};

	}
);