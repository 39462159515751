/* app/page/calendar */

import $ from 'jquery';

import Calendar from 'app/ui/calendar/calendar-default';
import Accordion from 'app/ui/accordion/simple';

'use strict';

Calendar.init();
Accordion.init();
$('.js-print').on('click',function(e) {
    e.preventDefault();
    window.print();
});