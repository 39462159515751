define(
    [
        'jquery'
    ],

    function ($) {

        'use strict';

        var Accordion;
        var selectors = {
            wrapper: '.js-expand-collapse',
            header: '.js-expand-collapse__header',
            button: '.js-expand-collapse__button'
        };
        var classes = {
            expanded: 'is-expanded',
            collapsed: 'is-collapsed'
        };

        return {

            init: function () {
                Accordion = this;

                Accordion._initEvents();
                Accordion._setDefaultState();
            },

            reInit: function () {
                Accordion._initEvents();
            },

            _initEvents: function () {
                $(document).off('click', selectors.header, Accordion._processHeaderClick);
                $(document).on('click', selectors.header, Accordion._processHeaderClick);
                $(document).off('keypress', selectors.header, Accordion._processKeyboardClick);
                $(document).on('keypress', selectors.header, Accordion._processKeyboardClick);

                $(document).off('click', selectors.button, Accordion._processHeaderClick);
                $(document).on('click', selectors.button, Accordion._processHeaderClick);

                $(document).off('click', 'body', Accordion._processBodyClick);
                $(document).on('click', 'body', Accordion._processBodyClick);
            },

            _setDefaultState: function () {
                $(selectors.header).add(selectors.button)
                    .each(function (idx, el) {
                        var $header = $(el);
                        var $wrapper = $header.closest(selectors.wrapper);
                        if (!$wrapper.hasClass(classes.expanded) && !$wrapper.hasClass(classes.collapsed)) {

                            var ariaExpanded = $header.attr('aria-expanded');
                            if (typeof ariaExpanded !== typeof undefined) {
                                $wrapper.addClass(ariaExpanded ? classes.expanded : classes.collapsed);
                            } else {
                                $wrapper.addClass(classes.collapsed);
                                $header.attr('aria-expanded', false);
                            }
                        } else {
                            $header.attr('aria-expanded', $wrapper.hasClass(classes.expanded));
                        }
                    });
            },

            _processHeaderClick: function (event) {
                event.preventDefault();
                event.stopPropagation();
                var $header = $(event.target).closest(selectors.header)
                if ($header.length == 0) $header = $(event.target).closest(selectors.button);
                var $wrapper = $header.closest(selectors.wrapper);

                if (!$wrapper.hasClass(classes.expanded) && !$wrapper.hasClass(classes.collapsed)) {
                    var ariaExpanded = $header.attr('aria-expanded');
                    if (typeof ariaExpanded !== typeof undefined) {
                        $wrapper.addClass(ariaExpanded ? classes.expanded : classes.collapsed);
                    } else {
                        $wrapper.addClass(classes.expanded);
                        $header.attr('aria-expanded', true);
                    }
                } else {
                    $wrapper.toggleClass(classes.expanded + ' ' + classes.collapsed);
                    $header.attr('aria-expanded', $wrapper.hasClass(classes.expanded));
                }
                setTimeout(function () {
                    $(window).trigger('resize');
                }, 100);
            },

            _processBodyClick: function (event) {
                var $wrappers = $(selectors.wrapper).filter(function () {
                    var $this = $(this);

                    var shouldClose = $this.attr('data-touch-outside-close') === 'true';
                    var isOpen = $this.hasClass(classes.expanded);

                    return shouldClose && isOpen;
                });

                $wrappers.removeClass(classes.expanded);
            },

            _processKeyboardClick: function (event) {
                var enter = 13;
                var space = 32;
                if (event.keyCode == enter || event.keyCode == space) {
                    Accordion._processHeaderClick(event);
                }
            }
        };
    }
);