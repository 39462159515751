/* app/ui/calendar/calendar-default */

define(
	[
		'jquery',
		'app/ui/calendar/calendar-filter',
		'pubsub'
	],
	function ($, CalendarFilter) {

		'use strict';

		var Calendar;
		var $calendar;
		var $content;
		var $tabMenu;
		var id;

		var selectors = {
			calendar: '.js-calendar',
			content: '.js-calendar-content',
			tabMenu: '.js-calendar-tabmenu',
			tabItem: '.js-calendar-tabitem',
			tabLink: '.js-calendar-tablink',
			navLink: '.js-calendar-navlink',
			dayLink: '.js-calendar-daylink',

			tabWrap: '.js-tab__wrap',
			tabSelect: '.js-tab__select'
		};

		var classes = {
			selected: 'is-selected',
			loading: 'is-loading'
		};

		return {

			init: function() {
				Calendar = this;

				$calendar = $(selectors.calendar);
				$content = $calendar.find(selectors.content);
				$tabMenu = $calendar.find(selectors.tabMenu);
				id = Math.floor(Math.random() * (1000 - 1) + 1);

				CalendarFilter.init($calendar, $content);
				Calendar._initEvents();
				Calendar._initSubscriptions();
			},

			// Listeners for navigation changes
			_initEvents: function () {
				$tabMenu.on('click', selectors.tabLink, Calendar._processTabClick);
				$content.on('click', selectors.navLink, Calendar._processNavClick);
				$content.on('click', selectors.dayLink, Calendar._processDayClick);

				$calendar.on('change', selectors.tabSelect, Calendar._processTabSelectChange);
			},

			// 1. Set calendar to loading state as AJAX call is made
			// 2. Remove loading state from calendar as new data is inserted
			// 3. Load new data returned
			_initSubscriptions: function() {
				$.subscribe('/calendar/loading', Calendar._setLoadingState);
				$.subscribe('/calendar/loaded', Calendar._removeLoadingState);
				$.subscribe('/ajax/ready/' + id, Calendar._loadNewContent);
			},

			// Set loading state
			_setLoadingState: function() {
				$content.addClass(classes.loading);
			},

			// Remove loading state
			_removeLoadingState: function () {
				$content.removeClass(classes.loading);
			},

			// Runs on click of calendar tab menu links
			// 1. Do nothing is tab already selected
			// 2. Update tabs display
			// 3. Fetch new content
			_processTabClick: function(event) {
				event.preventDefault();
				var $link = $(this);
				var $item = $link.closest(selectors.tabItem);

				if ($item.hasClass(classes.selected)) {
					return;
				}

				Calendar._updateTabDisplay($item);
				Calendar._fetchNewContent($link);
			},

			_processTabSelectChange: function (event) {
				// Delegates to tab click
				var $select = $(event.target).closest(selectors.tabSelect),
					value = $select.val(),
					$wrap = $select.closest(selectors.tabWrap),
					$links = $wrap.find(selectors.tabLink),
					$link = $links.filter('[href="' + value + '"]');

				$link.trigger('click');
			},

			// Runs on click of calendar next/prev arrows
			// 1. Fetch new data
			_processNavClick: function (event) {
				event.preventDefault();
				var $link = $(this);

				Calendar._fetchNewContent($link);
			},

			// Runs on click of a day in calendar month view
			// 1. Update first tab item (Day) to selected state
			// 2. Fetch new data
			_processDayClick: function(event) {
				event.preventDefault();
				var $link = $(this);
				var $dayTab = $tabMenu.find(selectors.tabItem).first();

				Calendar._updateTabDisplay($dayTab);
				Calendar._fetchNewContent($link);
			},

			// Util function to update selected tab display
			// $item = Tab item to have selected state
			// 1. Remove 'is-selected' class from all siblings
			// 2. Add 'is-selected' class to item
			_updateTabDisplay: function ($item) {
				$item.siblings(selectors.tabItem).removeClass(classes.selected);
				$item.addClass(classes.selected);
			},

			// Util function to retrieve new data via AJAX
			// 1. Get url from data attribute
			// 2. Set loading state
			// 3. Publish event with url and unique id variables
			_fetchNewContent: function ($link) {
				var url = $link.attr('data-ajax-url');

				// Set container to loading state

				if (typeof(url) !== 'undefined' && url !== '') {
					$.publish('/calendar/loading');
					$.publish('/ajax/get', [{ url: url, id: id }]);
				}
			},

			// Runs when new data is returned
			// 1. Apply any filtering to new HTML
			// 2. Populate container with new HTML
			// 3. Remove loading state
			_loadNewContent: function (data) {
				var filteredHtml = CalendarFilter.processNewContent(data.html);

				$content.html(filteredHtml);
				$.publish('/calendar/loaded');
			}
		};
	}
);