/* app/page/homepage */
import MosaicRotator from 'app/ui/rotator/mosaic-rotator';
import FbResize from 'app/ui/social/fb-resize';

import $ from 'jquery';
import 'app/page/calendar';
import 'matchHeight';

MosaicRotator.init();
FbResize.init();
var equaliseGroup = function () {
    var $group = $(this);
    $group.find('.js-equalise').matchHeight();
};
$('.js-equalise-group').each(equaliseGroup);