/* app/ui/rotator/home-hero */
/* app/ui/rotator/home-hero */
import Hammer from 'hammer';

import $ from 'jquery';
import MediaQueries from 'util/mediaqueries';
import VideoPlayer from 'app/ui/video/video.player';
import 'pubsub';
import 'brotator';
import 'carousel';
import 'scrollspy';
import templayed from 'templayed';

var MosaicRotator;
var $items, $next, $prev;
var $featureRotator = null;
var $content = null;
var $viewport = null;
var interval = 0;
var selectors = {
    desktopMainView: '.js-mosaic-rotator__main-view',
    rotatorThumbnail: '.js-mosaic-rotator__thumbnail',
    mobileRotator: '.js-mosaic-rotator',
    mobileRotatorItem: '.js-rotator-item--mobile',
    currentSlideNum: '.js-current-slide',
    titleAnnouncement: '#js-mosaic-rotator-status'
}
var $desktopMainView;

export default {
    init: function () {
        MosaicRotator = this;
        MosaicRotator._mobileUrlGenerate();
        MosaicRotator._initMediaQueries();
        $('.rotator__swipe').on('scrollSpy:enter', function (e) {
            var $swipe = $(e.target);

            if (!$swipe.hasClass('in-view')) {
                $swipe.addClass('in-view');
            }
        }).scrollSpy();
    },

    _mobileUrlGenerate: function () {
        var $videoItem = $(selectors.rotatorThumbnail).filter('[data-type="video"]');
        $videoItem.each(function (index, item) {
            var $item = $(item);
            var embedUrl = $item.attr('data-target-url');
            var watchUrl = embedUrl.replace('embed/', 'watch?v=');
            $item.attr('href', watchUrl);
        });
    },

    _initMediaQueries: function () {
        MediaQueries.register([{
            //homepage hero small version
            queries: MediaQueries.queries['below-768'],
            shouldDegrade: false,
            match: function () {
                MosaicRotator._initRotator();
            },
            unmatch: function () {
                if ($featureRotator !== null && $featureRotator.length) {
                    $featureRotator.brotator('destroy');
                }
            }
        }, {
            //homepage hero large version
            queries: MediaQueries.queries['768-plus'],
            shouldDegrade: true,
            match: function () {
                MosaicRotator._initDesktop();
            },
            unmatch: function () {
                $(selectors.rotatorThumbnail).off('click', MosaicRotator._renderDesktopMain);
            }
        }]);
    },

    _initDesktop: function () {
        $(selectors.rotatorThumbnail).on('click', MosaicRotator._renderDesktopMain);
        MosaicRotator._desktopAutoPlay();
        $(selectors.rotatorThumbnail).first().trigger('click');
        $.subscribe('video/play', MosaicRotator._desktopStopAutoPlay);
    },

    _renderDesktopMain: function (event) {
        event.preventDefault();
        if (!event.isTrigger) {
            MosaicRotator._desktopStopAutoPlay();
        }
        $(selectors.rotatorThumbnail).closest('.js-rotator-item--mobile').removeClass('is-selected');
        var $target = $(event.target).closest(selectors.rotatorThumbnail);
        var dataType = $target.attr('data-type');

        var replaceImage = $target.attr('data-replace-image');
        var selectedUrl = $target.attr('data-target-url');
        var title = $target.attr('data-target-title');
        var insertTemplate;
        var template;
        var compiledTemplate;

        $desktopMainView = $(selectors.desktopMainView);
        $target.closest('.js-rotator-item--mobile').addClass('is-selected');

        if (dataType === 'video') {
            insertTemplate = $('#js-mosaic-video-insert').html();
            template = insertTemplate.replace(/(\r\n|\n|\r)/gm, '');
            compiledTemplate = templayed(template)({ url: selectedUrl, image: replaceImage, title: title });
            $desktopMainView.fadeOut(500, function () {
                $(this).html(compiledTemplate).fadeIn();
                setTimeout(VideoPlayer.init($('.js-mosaic-rotator__main-view')), 300);
            });
        }
        else {
            insertTemplate = $('#js-mosaic-image-insert').html();
            template = insertTemplate.replace(/(\r\n|\n|\r)/gm, '');
            compiledTemplate = templayed(template)({ url: selectedUrl, image: replaceImage, title: title });
            $desktopMainView.fadeOut(500, function () {
                $(this).html(compiledTemplate).fadeIn();
            });

        }
    },

    _desktopAutoPlay: function () {
        var itemCount = $(selectors.rotatorThumbnail).length;
        var itemIndex = 1;
        interval = setInterval(function () {
            $(selectors.rotatorThumbnail).eq(itemIndex).trigger('click');
            itemIndex++;
            if (itemIndex === itemCount) {
                itemIndex = 0;
            }
        }, 5000);
    },

    _desktopStopAutoPlay: function () {
        clearInterval(interval);
    },

    //only in mobile version, init the rotator
    _initRotator: function () {
        var itemSelector = selectors.mobileRotatorItem;
        $featureRotator = $('.js-mosaic-rotator');
        $content = $featureRotator.find('.js-rotator__content');
        $viewport = $featureRotator.find('.rotator__viewport');
        $items = $content.find(itemSelector);
        $next = $featureRotator.find('.js-rotator__next');
        $prev = $featureRotator.find('.js-rotator__prev');
        $.subscribe('/rotator/transition/start', MosaicRotator._onRotate);

        if ($items.length > 1) {
            var rotatorConfig = {
                content: '.js-rotator__content',
                timeout: 6000,
                hasMenu: false,
                hasButtons: true,
                item: itemSelector,
                next: '.js-rotator__next',
                previous: '.js-rotator__prev',
                animationSpeed: 500,
                lazyloader: true,
                namespace: '/rotator',
                autorotate: true

            };

            $featureRotator.brotator(rotatorConfig);
            // Swipe with hammer
            Hammer($featureRotator[0], {
                stop_browser_behavior: false,
                drag_lock_to_axis: true
            }).on('dragleft dragright swipeleft swiperight', MosaicRotator.handleHammer);
            $featureRotator.addClass('rotator--slide');
            $content.addClass('animate');


            // Trigger title announcement
            $.subscribe('/rotator/state/updated', function (data) {
                var slide = $('.js-rotator__content > li').eq(data.current);
                $(selectors.titleAnnouncement).text(slide.find('a').data('target-title'));
            });
        }
    },

    _onRotate: function (index) {
        $(selectors.currentSlideNum).text(index + 1);
    },

    stopAuto: function () {
        $featureRotator.brotator('stopAutoRotate');
    },

    handleHammer: function (ev) {
        // disable browser scrolling
        // ev.gesture.preventDefault();

        switch (ev.type) {
            case 'swipeleft':
                $content.addClass("animate");
                $next.click();
                ev.gesture.stopDetect();
                break;

            case 'swiperight':
                $content.addClass("animate");
                $prev.click();
                ev.gesture.stopDetect();
                break;
        }
        MosaicRotator.stopAuto();
    }
};